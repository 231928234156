<template>
  <b-card
    no-body
  >
    <b-card-body>
      <template
        v-if="userData && userData.access_course_checo == 1"
      >
        <div class="text-center">
          <h1>MINDEXT: CURSO IDIOMA CHECO</h1>
        </div>
        <curso-view typeCourse="checo" />
      </template>
      <template
        v-else
      >
        <b-alert
          variant="danger"
          :show="userData && userData.access_course_checo == 0"
        >
          <h4 class="alert-heading">
            Este usuario no tiene acceso al curso de checo
          </h4>
        </b-alert>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  BCard, BCardBody, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import cursoView from '@/components/course/cursoView.vue'
import userStoreModule from '@/views/users/user/userStoreModule'

export default {
  name: 'Traveler',
  components: {
    BCard,
    BCardBody,
    BAlert,
    // BCardTitle,
    // BCardSubTitle,
    // BButton,

    'curso-view': cursoView,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      userData: null,
    }
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    setDataUser() {
      const userId = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('app-user/fetchUser', { id: userId.id })
        .then(response => {
          this.userData = response.data.userData
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
  },
}
</script>

<style>

</style>
